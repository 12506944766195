const Module = {
    state() {
        return {
            jwt: null,
            user: null
        }
    },
    getters: {
        jwt(state) {
            return state.jwt
        },

        user(state) {
            return state.user
        },

    },
    actions: {},
    mutations: {
        initJwt(state, jwt) {
            state.jwt = jwt
        },
        initUser(state, user) {
            state.user = user
        },
        logout(state) {
            state.user = null
            state.jwt = null
        }
    }

}

export default Module