<template>
  <div class="salons">
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto" rounded>
          <v-card-title>Salons
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="setDialogAddStatus(true)">
              Ajouter
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="salons"
          >
            <template v-slot:[`item.actions`]="{}">
              <v-btn icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>


        </v-card>
      </v-col>
    </v-row>
    <AddDialog :opened="addDialog" @setOpened="setDialogAddStatus" :type="'salon'"></AddDialog>
  </div>
</template>

<script>
import api from "@/plugins/api";
import AddDialog from "@/components/Dialogs/AddDialog";

export default {
  name: "SalonView",
  components: {
    AddDialog
  },
  computed: {
    salons() {
      return this.$store.getters.salons
    }
  },
  data() {
    return {
      headers: [
        {text: "Nom", align: "start", sortable: false, value: "nom"},
        {text: "Nombre de prospects synchronisés", value: "nombrePro", sortable: false},
        {text: "Actions", value: "actions", sortable: false},
      ],
      addDialog: false
    }
  },
  mounted() {
    this.getSalonSf()
    this.getSalons()
  },
  methods: {
    getSalonSf() {
      let that = this
      api.get(process.env.VUE_APP_BACK + '/sfGetSalons').then(function (res) {
        that.$store.commit('setSalonsSf', res.data.records[0].Metadata.valueSet.valueSetDefinition.value)
        //console.log('salonsSf', that.$store.getters.salonsSf)
      })
    },
    getSalons() {
      let that = this
      api.get(process.env.VUE_APP_BACK + '/salons', {}).then(function (res) {
        //console.log(res.data)
        that.$store.commit('setSalons', res.data)
      }).catch(function (error) {
        console.log(error)
      })
    },
    setDialogAddStatus(status) {
      this.addDialog = status

    }
  }
}
</script>

<style scoped>

</style>