<template>
  <v-dialog v-model="isOpened" scrollable>
    <v-card v-if="item !== null">
      <v-card-title v-if="type=== 'produit'">{{ item.name }}</v-card-title>
      <v-card-title v-if="type === 'prospect'">{{ item.civilite }}. {{ item.nom }} {{ item.prenom }}</v-card-title>
      <v-card-text v-if="type === 'produit'">
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Nom :
          </v-col>
          <v-col cols="10">
            {{ item.name }}
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Programme :
          </v-col>
          <v-col cols="10">
            {{ item.programme }}
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Email du proprietaire :
          </v-col>
          <v-col cols="10">
            {{ item.owner_email }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            ID SalesForce du proprietaire :
          </v-col>
          <v-col cols="10">
            {{ item.owner_id }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            ID Akénéo du produit :
          </v-col>
          <v-col cols="10">
            {{ item.akeneo_id }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-1 align-center align-center">
          <v-col cols="2" class="font-weight-bold">
            Niveaux d'études:
          </v-col>
          <v-col cols="10">
            <ul>
              <li class="mb-2" v-for="niv in item.niveaux" :key="niv">
                <v-chip small color="info">{{ niv }}</v-chip>
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
           Type de compte :
          </v-col>
          <v-col cols="10">
            {{ item.account_type }}
          </v-col>
        </v-row>
      </v-card-text>


      <v-card-text v-if="type === 'prospect'">
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Civilité :
          </v-col>
          <v-col cols="10">
            {{ item.civilite }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Nom :
          </v-col>
          <v-col cols="10">
            {{ item.nom }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Prénom:
          </v-col>
          <v-col cols="10">
            {{ item.prenom }}
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Email :
          </v-col>
          <v-col cols="10">
            {{ item.email }}
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Téléphone :
          </v-col>
          <v-col cols="10">
            {{ item.tel }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Code Postal :
          </v-col>
          <v-col cols="10">
            {{ item.code_postal }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Ville :
          </v-col>
          <v-col cols="10">
            {{ item.ville }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Etablissement :
          </v-col>
          <v-col cols="10">
            {{ item.etablissement }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Niveau :
          </v-col>
          <v-col cols="10">
            {{ item.niveau }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Produit :
          </v-col>
          <v-col cols="10">
            <div class="programme" v-for="s in item.specSf" :key="s.id">
              {{ s.name }}
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            ID SalesForce :
          </v-col>
          <v-col cols="10">
            {{ item.id_SF }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="type === 'prospect'">
        <v-spacer></v-spacer>
        <v-btn color="#004f91" dark @click="goToSF" :disabled="!item.id_SF === true">Fiche SalesForce</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "showDialog",
  props: ["opened", "item", "type"],
  computed: {
    isOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('setOpened', value)
      },
    },
  },
  methods: {
    goToSF() {
      window.location.href = process.env.VUE_APP_SF + this.item.id_SF + "/view"
    }
  }

}
</script>

<style scoped>

</style>