<template>
  <v-dialog v-model="isOpened" width="85px" height:85px style="overflow: hidden; min-width: 0% !important;" persistent>
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate></v-progress-circular>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingDialog",
  props: ["opened",],
  data() {
    return {}
  },
  computed: {
    isOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('setOpened', value)
      },
    },
  },
}
</script>

<style>
.v-dialog {
  width: fit-content !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

</style>