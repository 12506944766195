<template>
  <div class="prospects">
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000" top>
      <v-icon>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
    </v-snackbar>
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto" rounded>
          <v-card-title
          >Prospects
            <v-spacer></v-spacer>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>Filtrer le tableau</v-card-title>
                <v-card-text>
                  <v-select
                      :items="salons"
                      item-text="label"
                      item-value="label"
                      label="Filtrer par salons"
                      v-model="filters.salonFilter"
                  ></v-select>
                  <v-text-field
                      v-model="filters.prospectFilter"
                      label="Filter par nom"
                      @keyup.enter="filterProspect"
                  ></v-text-field>
                  <v-checkbox
                      label="Uniquement marqués comme erreur salesforce"
                      v-model="filters.SFErrorsFilter"
                  ></v-checkbox>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text color="error" @click="resetFilters">
                    Vider les filtres
                  </v-btn>
                  <v-btn color="primary" text @click="filterProspect">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-card-title>

          <v-data-table :headers="headers" :items="prospects">
            <template v-slot:[`item.salon`]="{ item }">
              <div v-if="item.salon">{{ item.salon }}</div>
              <v-tooltip bottom v-if="!item.salon">
                <template
                    v-slot:activator="{ on, attrs }"
                    v-if="$store.getters.user.role === 'admin'"
                >
                  <div v-bind="attrs" v-on="on">Non synchronisé</div>
                </template>
                <span>Salon provisoire: {{ item.salon_nom }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.specSf`]="{ item }">
              <div class="programme" v-for="s in item.specSf" :key="s.id">
                <v-chip class="ma-1">{{ s.name }}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ format(new Date(item.createdAt), "DD/MM/YYYY - HH:mm") }}
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ format(new Date(item.updatedAt), "DD/MM/YYYY - HH:mm") }}
            </template>
            <template v-slot:[`item.statut_SF`]="{ item }">
              <v-chip
                  dense
                  color="success"
                  text-color="white"
                  v-if="item.statut_SF === true"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip
                  color="error"
                  text-color="white"
                  v-if="item.statut_SF === false"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom v-if="item.statut_SF === false && item.salon">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="exportToSalesForce(item)"
                      class="showIcon"
                  >
                    <v-icon>mdi-export</v-icon>
                  </v-btn>
                </template>
                <span>Exporter vers SalesForce</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openShowDialog(item)"
                      class="showIcon"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Details</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openEditDialog(item)"
                      class="editIcon"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template
                    v-slot:activator="{ on, attrs }"
                    v-if="$store.getters.user.role === 'admin'"
                >
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="deleteIcon"
                      @click="openDeleteDialog(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ShowDialog
        :opened="showDialog"
        @setOpened="setDialogOpenStatus"
        :item="selectedProspect"
        :type="showType"
    ></ShowDialog>
    <EditDialog
        :opened="editDialog"
        @setOpened="setDialogEditStatus"
        :item="editedItem"
        :type="'prospect'"
        @editDone="editDone"
    ></EditDialog>
    <delete-dialog
        v-if="prospectToDelete"
        :opened="deleteDialog"
        :itemName="prospectToDelete.nom"
        @setOpened="setDialogDeleteStatus"
        @confirm="deleteProspect"
    ></delete-dialog>
    <loading-dialog
        class="loadingDialog"
        :opened="loadingOpened"
    ></loading-dialog>
  </div>
</template>

<script>
import ShowDialog from "@/components/Dialogs/ShowDialog";
import EditDialog from "@/components/Dialogs/EditDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import api from "@/plugins/api";
import {format} from "fecha";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";

export default {
  name: "ProspectView",
  components: {
    LoadingDialog,
    DeleteDialog,
    ShowDialog,
    EditDialog,
  },
  mounted() {
    this.specSF = this.$store.getters.sfConfs;
    this.resetFilters();
    this.formatProspects();
  },
  computed: {
    salons() {
      return this.$store.getters.salonsSf;
    },
  },
  data() {
    return {
      headers: [
        {text: "Nom", align: "start", sortable: true, value: "nom"},
        {text: "Prenom", value: "prenom", sortable: true},
        {text: "Email", value: "email", sortable: true},
        {text: "Produits Choisis", value: "specSf", sortable: true},
        {text: "Salon", value: "salon", sortable: true},
        {text: "Date de création", value: "createdAt", sortable: true},
        {
          text: "Date de dernière modification",
          value: "updatedAt",
          sortable: true,
        },
        {text: "Statut SalesForce", value: "statut_SF", sortable: true},
        {text: "Actions", value: "actions", sortable: false, align: "center"},
      ],
      showDialog: false,
      showType: "prospect",
      editDialog: false,
      editedItem: null,
      snackbar: {
        show: false,
        text: "",
        color: "",
        icon: "",
      },
      selectedProspect: null,
      prospectToDelete: null,
      deleteDialog: false,
      prospects: [],
      menu: false,
      specSF: [],
      filters: {
        salonFilter: "",
        prospectFilter: "",
        SFErrorsFilter: false,
      },
      loadingOpened: false,
    };
  },
  methods: {
    setDialogOpenStatus(status) {
      this.showDialog = status;
    },
    openShowDialog(item) {
      this.selectedProspect = item;
      this.setDialogOpenStatus(true);
    },
    setDialogEditStatus(status) {
      this.editDialog = status;
    },
    openEditDialog(item) {
      let that = this;
      this.editedItem = item;
      that.setDialogEditStatus(true);
    },
    editDone() {
      this.setDialogEditStatus(false);
      this.snackbar.show = true;
      this.snackbar.text = "Prospect modifié";
      this.snackbar.color = "success";
      this.snackbar.icon = "mdi-check";
      this.formatProspects();
    },
    openDeleteDialog(item) {
      this.prospectToDelete = item;
      this.setDialogDeleteStatus(true);
    },
    setDialogDeleteStatus(status) {
      this.deleteDialog = status;
    },
    deleteProspect() {
      let that = this;
      api
          .post(process.env.VUE_APP_BACK + "/deleteProspect", {
            id: this.prospectToDelete.prospect_id,
          })
          .then(function (response) {
            that.setDialogDeleteStatus(false);
            if (response.data.error) {
              throw new Error(response.data.error);
            }
            ////console.log(response);
          });
    },
    format,
    async exportToSalesForce(item) {
      this.loadingOpened = true;

      let prospect = this.formatProspectForSF(item);
      let checkData = {
        email: prospect.personEmail,
        recordType: process.env.VUE_APP_RECORD_TYPE_ID,
      };

      try {
        const resCheck = await api.post(`${process.env.VUE_APP_BACK}/sfCheckProspect`, checkData);

        let prospectData = {
          prospect: prospect,
          prospectId: item.prospect_id,
        };

        if (resCheck.data.exist) {
          prospectData.prospectSFId = resCheck.data.id;

          const resUpdate = await api.post(`${process.env.VUE_APP_BACK}/sfUpdateProspect`, prospectData);

          if (resUpdate.data.status === 400) {
            throw new Error("Erreur lors de la synchronisation, un administrateur a été prévenu");
          }
        } else {
          const resCreate = await api.post(`${process.env.VUE_APP_BACK}/sfCreateProspect`, prospectData);
          if (resCreate.data.status === 400) {
            throw new Error("Erreur lors de la synchronisation, un administrateur a été prévenu");
          }

          ////console.log(resCreate.data);
        }

        await this.formatProspects();
        this.loadingOpened = false;
      } catch (error) {
        this.loadingOpened = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.snackbar.color = "error";
        this.snackbar.icon = "mdi-window-close";
      } finally {
        this.dialog = false;
      }
    },
    formatProspectForSF(prospectToSync) {
      const sfConfs = this.$store.getters.sfConfs;
      const isSpecArray = JSON.stringify(prospectToSync.spec_id).startsWith("[");

      let spec;
      if (isSpecArray) {
        prospectToSync.specSf = sfConfs.filter((item) =>
            prospectToSync.spec_id.includes(item.id)
        );
        spec = sfConfs.find(item => prospectToSync.spec_id[0] === item.id);
      } else {
        spec = sfConfs.find(x => x.id === prospectToSync.spec_id[0]);
        prospectToSync.specSf = [spec];
      }

      const formattedProspectSf = this.prepareProspectData(prospectToSync, spec);

      if (prospectToSync.etablissement === null) {
        prospectToSync.etablissement = "";
      } else if (prospectToSync.etablissement.startsWith("Saisie Libre :") || prospectToSync.etablissement.startsWith("Pas dans salesforce :")) {
        formattedProspectSf.Description = prospectToSync.etablissement;
      } else {
        formattedProspectSf.Etablissement_du_prospect__pc = prospectToSync.etablissement;
      }

      Object.keys(formattedProspectSf).forEach((key) => {
        if (formattedProspectSf[key] === null || formattedProspectSf[key] === "null") {
          delete formattedProspectSf[key];
        }
      });
      return formattedProspectSf;
    },

    prepareProspectData(prospectToSync, spec) {
      const prospectSf = {
        salutation: prospectToSync.civilite,
        lastName: prospectToSync.nom,
        firstName: prospectToSync.prenom,
        personEmail: prospectToSync.email,
        personMailingPostalCode: prospectToSync.code_postal,
        personMailingCity: prospectToSync.ville,
        Date_de_contact__pc: prospectToSync.date_contact,
        Phone: prospectToSync.tel,
        recordTypeId: spec.account_record_type,
        ownerId: spec.owner_id,
        Type_compte__pc: spec.account_type,
        Statut__pc: "Prospect",
        origine_du_contact__pc: "Salon",
        Detail_de_l_origine__pc: prospectToSync.salon,
        Niveau_de_formation__pc: prospectToSync.niveau.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, ""),
        Programmes_choisis__c: prospectToSync.specSf.map(item => item.akeneo_id.toString()).join(";")
      };
      return prospectSf;
    },
    formatProspects() {
      this.$store.dispatch("fetchProspects").then(() => {
        let sp = this.$store.getters.salonsProvisoires;
        let p = this.$store.getters.prospects;
        if (p.length > 0) {
          for (let i = 0; i < p.length; i++) {
            if (Array.isArray(p[i].spec_id)) {
              p[i].specSf = this.$store.getters.sfConfs.filter((item) =>
                  p[i].spec_id.includes(item.id)
              );
              p[i].specSf.filter((x) => x !== undefined);
            } else {
              p[i].specSf = [];
              p[i].specSf.push(
                  this.$store.getters.sfConfs.filter(
                      (x) => x.id === parseInt(p[i].spec_id)
                  )[0]
              );
              p[i].specSf.filter((x) => x !== undefined);
            }

            ////console.log("salon_ id", p[i].salon_nom);
            let synchro =
                sp.filter((x) => x.salonSF === p[i].salon_nom).length > 0;
            if (synchro === true) {
              ////console.log("synchro ok");
              p[i].salon = sp.filter(
                  (x) => x.salonSF === p[i].salon_nom
              )[0].salonSF;
              ////console.log("synchro", p[i].salon);
            } else {
              ////console.log("pas synchro");
              p[i].salon = null;
            }
          }

          this.prospects = p;
          this.filterProspect();
        }
      });
    },
    filterProspect() {
      this.prospects = this.$store.getters.prospects;
      if (
          this.filters.salonFilter !== "" &&
          this.filters.prospectFilter !== ""
      ) {
        this.filterByInput(
            this.filterBySalon(this.prospects),
            this.filters.prospectFilter
        );
      } else {
        if (
            this.filters.salonFilter !== "" &&
            this.filters.prospectFilter === ""
        ) {
          this.prospects = this.filterBySalon(this.prospects);
        }
        if (
            this.filters.salonFilter === "" &&
            this.filters.prospectFilter !== ""
        ) {
          this.prospects = this.filterByInput(
              this.prospects,
              this.filters.prospectFilter
          );
        }
        if (
            this.filters.salonFilter === "" &&
            this.filters.prospectFilter === ""
        ) {
          this.resetFilters();
        }
      }

      if (this.filters.SFErrorsFilter === true) {
        this.prospects = this.prospects.filter((x) => x.statut_SF === false);
      }
      ////console.log(this.prospects);
      this.menu = false;
    },
    filterBySalon(arr) {
      return arr.filter((x) => x.salon === this.filters.salonFilter);
    },
    filterByInput(arr, str) {
      return arr.filter((x) =>
          Object.values(x).join(" ").toLowerCase().includes(str.toLowerCase())
      );
    },
    resetFilters() {
      this.filters.prospectFilter = "";
      this.filters.salonFilter = "";
      this.prospects = this.$store.getters.prospects;
    },
  },
};
</script>

<style scoped></style>
