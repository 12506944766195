<template>
  <v-app id="app">
    <v-app-bar
        elevation="4"
        style="max-height: 4rem;"
        color="#004f91"
        dark
    >
      <v-app-bar-nav-icon @click="drawer = true" v-if="user !== null"></v-app-bar-nav-icon>
      <v-toolbar-title>Administration Salon</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        color="#f8f7f7"

    >
      <v-list
          nav
          dense
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Administration Salon
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mb-2"></v-divider>
        <v-list-item-group
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="$router.push('/panelAdmin')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/programmesAdmin')">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Programmes</v-list-item-title>
          </v-list-item>

          <!--          <v-list-item @click="$router.push('/salonsAdmin')">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-account-multiple</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-title>Salons</v-list-item-title>-->
          <!--          </v-list-item>-->

          <v-list-item @click="$router.push('/prospectsAdmin')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Prospects</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Deconnexion</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view/>
    <loading-dialog :opened="loadingOpened"></loading-dialog>
  </v-app>
</template>

<script>
import loadingDialog from "@/components/Dialogs/LoadingDialog";

export default {
  name: 'App',
  components: {
    loadingDialog
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  mounted() {
  },
  data() {
    return {
      drawer: false,
      loadingOpened: false
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      window.localStorage.removeItem('jwt');
      this.$router.push('/loginAdmin')
    }
  }
}
</script>

<style lang="scss">
@import "assets/backoffice";


</style>
