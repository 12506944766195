

const Module = {
    state() {
        return {
            salesforce: {
                instanceUrl: null,
                accessToken: null,
            },
        }
    },
    getters: {
        salesforce(state) {
            return state.salesforce
        },
    },
    actions: {

    },
    mutations: {

    }
}

export default Module