<template>
  <v-dialog v-model="isOpened" max-width="500">
    <v-card>
      <v-card-title>Voulez vous vraiment supprimer {{itemName}} ?</v-card-title>
      <v-card-text><v-divider></v-divider></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancelDelete">Annuler</v-btn>
        <v-btn color="success" @click="confirmDelete">Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: ["opened", "itemName"],
  computed: {
    isOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('setOpened', value)
      },
    },
  },
  methods:{
    cancelDelete(){
      this.$emit('setOpened', false)
    },
    confirmDelete(){
      this.$emit('confirm')
    },
  }

}
</script>

<style scoped>

</style>