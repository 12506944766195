import api from "@/plugins/api";

const Module = {
    state() {
        return {
            programmes: [],
            SFConfs: [],
            niveaux: ["Seconde",
                "Première",
                "Terminale",
                "Bac +1",
                "Bac +2",
                "Bac +3",
                "Bac +4"                ,
                "Bac +5",
                "> Bac +5",
                "Professionnel"]
        }
    },
    getters: {
        programmes(state) {
            // foreach programme count number of sfconf with programme_id = programme.id
            state.programmes.forEach(function (programme) {
                programme.nbSFConfs = 0
                state.SFConfs.forEach(function (sfConf) {
                    if (sfConf.programme_Id === programme.id)
                        programme.nbSFConfs++
                })
            })
            return state.programmes
        },
        niveaux(state) {
            return state.niveaux
        },

        sfConfs(state) {
            return state.SFConfs
        },

    },
    actions: {
        fetchProgrammes: function (context) {
            return api.get(process.env.VUE_APP_BACK + '/programmes')
                .then(response => {
                    context.commit('initProgrammes', response.data)
                })
        },
        fetchSfConfs: function (context) {
            return api.get(process.env.VUE_APP_BACK + '/getAllSFConfs')
                .then(response => {
                    //console.log(response)
                    response.data.forEach(function (element) {
                        if(element.niveaux)
                       element.niveaux = element.niveaux.split(',')
                    })
                    context.commit('initSfConfs', response.data)
                })
        }
    },
    mutations: {
        initProgrammes(state, bddProgrammes) {
            state.programmes = bddProgrammes
        },
        initSfConfs(state, SFConfs) {
            state.SFConfs = SFConfs
        },
        updateSFConfs(state, spec) {
            let objIndex = state.SFConfs.findIndex((obj => obj.id === spec.id));
            state.SFConfs[objIndex] = spec
        },
        updateProg(state, prog) {
            let objIndex = state.programmes.findIndex((obj => obj.id === prog.id));
            state.programmes[objIndex] = prog
        }
    }

}

export default Module