import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import salonProvisoireModule from "@/store/modules/salonProvisoire.store";
import salonsModule from "@/store/modules/salons.store"
import programmeModule from '@/store/modules/programme.store'
import prospectModule from '@/store/modules/prospects.store'
import authModule from '@/store/modules/auth.store'
import SFModule from '@/store/modules/salesforces.store'

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    modules: {
        salonProvisoireModule: salonProvisoireModule,
        salonsModule: salonsModule,
        programmeModule: programmeModule,
        prospectModule: prospectModule,
        authModule: authModule,
        SFModule: SFModule
    }
})

export default store