<template>
  <v-dialog v-model="isOpened" scrollable>
    <v-card>
      <v-card-title>
        Ajouter un {{ type }}
      </v-card-title>
      <v-card-text v-if="type==='produit'">
        <v-form ref="produitForm">
          <v-row class="ma-1 align-center">
            <v-col cols="2" class="font-weight-bold">
              Nom :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="newSpec.name" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="ma-1 align-center align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Email du proprietaire :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="newSpec.owner_email" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Id SalesForce du proprietaire :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="newSpec.owner_id" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Identifiant Akeneo :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="newSpec.akeneo_id" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Programme :
            </v-col>
            <v-col cols="10">
              <v-select v-model="newSpec.programme_Id" :items="programmes" item-text="nom" item-value="id"
                        :rules="rules.produit.default"></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Niveau d’étude/diplôme pour info programme
            </v-col>
            <v-col cols="10">
              <v-select v-model="newSpec.niveaux" :items="niveaux" chips multiple
                        :rules="rules.produit.select"></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Type de compte :
            </v-col>
            <v-col cols="10">
              <v-select v-model="newSpec.account_type" :items="typeDeComptes" item-text="text" item-value="value" :rules="rules.produit.default"></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-form>
      </v-card-text>
      <v-card-text v-if="type==='programme'">
        <v-form ref="programmeForm">
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Nom :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="newProg.nom" :rules="rules.programme"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancel">Annuler</v-btn>

        <v-btn color="success" @click="create">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/plugins/api";

export default {
  name: "AddDialog",
  props: ["opened", "type"],
  computed: {
    isOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('setOpened', value)
      },
    },
    salonsSf() {
      return this.$store.getters.salonsSf
    },
    programmes() {
      return this.$store.getters.programmes
    },
    niveaux() {
      return this.$store.getters.niveaux
    },
  },
  data() {
    return {
      newSalon: {},
      newProg: {},
      newSpec: {
        name: '',
        owner_email: '',
        owner_id: '',
        akeneo_id: '',
        programme_Id: '',
        account_type: '',
        niveaux: [],
      },
      dateDebMenu: false,
      dateFinMenu: false,
      typeDeComptes: [
        {text: 'Prospect SciencesCom', value: 'Prospect SciencesCom'},
        {text: 'Prospect MS', value: 'Prospect MS'},
        {text: 'Prospect BACH', value: 'Prospect ABM'},
        {text: 'Prospect GE', value: 'Prospect GE'},
      ],
      rules: {
        produit: {
          default: [value => {
            if (value) return true

            return 'Champs obligatoire.'
          }],
          select: [value => {
            if (value)
              if (value.length > 0 && value !== "") return true

            return 'Champs obligatoire.'
          }],
        },
        programme: [value => {
          if (value) return true

          return 'Champs obligatoire.'
        }
        ]
      },
    }
  },
  methods: {
    create() {
      let that = this
      switch (this.type) {
          // case 'salon':
          //   axios.post(process.env.VUE_APP_BACK + '/salons', {salons: [this.newSalon]}).then(function (res) {
          //     that.$store.commit('setSalons', res.data)
          //     that.isOpened = false
          //     that.newSalon = {}
          //   }).catch(function (error) {
          //     console.log(error)
          //   })
          //   break
        case 'programme':
          if (this.$refs.programmeForm.validate() === false) return
          api.post(process.env.VUE_APP_BACK + '/programmes', {programme: this.newProg}).then(function () {
            that.isOpened = false
            that.$store.dispatch('fetchProgrammes')
            that.newProg = {}
            that.$refs.programmeForm.reset()
          }).catch(function (error) {
            console.log(error)
          })
          break
        case 'produit':
          if (this.$refs.produitForm.validate() === false || this.newSpec.niveaux.length === 0) return
          this.newSpec.status = "Prospect"
          this.newSpec.account_record_type = process.env.VUE_APP_RECORD_TYPE_ID
          this.newSpec.niveaux = this.newSpec.niveaux.toString()
          api.post(process.env.VUE_APP_BACK + '/SFConf', {spec: this.newSpec}).then(function () {
            that.isOpened = false
            that.$store.dispatch('fetchSfConfs')
            that.newSpec = {
              name: '',
              owner_email: '',
              owner_id: '',
              akeneo_id: '',
              programme_Id: '',
              account_type: '',
              niveaux: [],
            }
            that.$refs.produitForm.resetValidation()
          }).catch(function (error) {
            console.log(error)
          })
          break
      }

    },
    cancel() {
      this.isOpened = false
      if (this.$refs.produitForm)
        this.$refs.produitForm.resetValidation()
      if (this.$refs.programmeForm)
        this.$refs.programmeForm.resetValidation()
    }
  },

  watch: {
    isOpened() {
      this.newProg = {}
      this.newSpec = {}
    }
  }
}
</script>

<style scoped>

</style>