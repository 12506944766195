import api from "@/plugins/api";

const salonProvisoireModule = {
    state() {
        return {
            salonsProvisoires: [],
            historique: []
        }
    },
    getters: {
        salonsProvisoires(state) {
            return state.salonsProvisoires

        },
        historique(state) {
            return state.historique;
        }
    },
    mutations: {
        setSalonsProvisoires(state, salons) {
            state.salonsProvisoires = salons
        },
        setHistorique(state, historique) {
            state.historique = historique
        }
    },
    actions: {
        fetchSalonsProvisoires(context) {
            return api.get(process.env.VUE_APP_BACK + '/salonsProvisoires')
                .then(response => {
                    context.commit('setSalonsProvisoires', response.data)
                })
        },
        fetchHistorique(context) {
            return api.get(process.env.VUE_APP_BACK + '/historique')
                .then(response => {
                    context.commit('setHistorique', response.data)
                })
        }
    }
}

export default salonProvisoireModule