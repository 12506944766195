import api from "@/plugins/api";

const salonsStore = {
    state() {
        return {
            salons: [],
            salonsSf: [],

        }
    },
    getters: {
        salons(state, getters, rootstate, rootGetters) {
            let p = rootGetters.prospects
            //console.log('p', rootGetters.prospects)
            let s = state.salons

            for (let i = 0; i < s.length; i++) {
                s[i].nombrePro = p.filter(x => x.salon_id === s[i].salon_id).length
            }
            return s
        },
        salonsSf(state) {
            return state.salonsSf
        },

    },
    actions: {
        fetchSalons(context) {
            return api.get(process.env.VUE_APP_BACK + '/salons')
                .then(response => {
                    context.commit('setSalons', response.data)
                })
        },
        fetchSalonsSf({commit}) {

            api.get(process.env.VUE_APP_BACK + '/sfGetSalons').then(function (res) {
                commit('setSalonsSf', res.data.records[0].Metadata.valueSet.valueSetDefinition.value)
            })
        }
    },
    mutations: {
        setSalons(state, salons) {
            state.salons = salons
        },
        setSalonsSf(state, salonsSf) {
            state.salonsSf = salonsSf
        },

    }
}

export default salonsStore