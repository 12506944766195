import api from "@/plugins/api";

const propspectsModule = {
    state(){
        return{
            prospects: [],
        }
    },
    getters:{
        prospects(state) {
            return state.prospects
        },
    },
    actions:{
        fetchProspects(context){
            return api.get(process.env.VUE_APP_BACK + '/prospects')
                .then(response => {
                    context.commit('initProspect', response.data)
                })
        }
    },
    mutations:{
        initProspect(state, prospects) {
            for (let i = 0; i < prospects.length; i++) {
                prospects[i].spec_id = JSON.parse(prospects[i].spec_id)
            }
            state.prospects = prospects
        },
        updateProspect(state, prospect){
            let objIndex = state.prospects.findIndex((obj => obj.programme_id === prospect.programme_id));
            state.SFConfs[objIndex] = prospect
        },

        addProspect(state, prospect) {
            state.prospects.push(prospect)
        },
        deleteProspect(state, id){
            state.prospects = state.prospects.filter(function( obj ) {
                return obj.prospect_id !== id;
            });
        },
        deleteProspectBySalon(state, id){
            let a = []
            state.prospects.map(obj => {
                if (obj.salon_id !== id) {
                    a.push(obj)
                }

            });
            state.prospects = a
        }
    }

}

export default propspectsModule