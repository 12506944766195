import axios from 'axios'
import store from '@/store/index.store'

let jwt = store.getters.jwt
if (jwt === null) {
    store.commit('initJwt', window.localStorage.getItem('jwt'))
    jwt = store.getters.jwt
}


const api = axios.create({
    baseURL: process.env.VUE_APP_BACK,
    headers: {
        authorization: store.getters.jwt
    }
})


export default     api
