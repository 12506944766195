<template>
  <div class="login">
    <v-row class="ma-6">
      <v-col>
        <v-card>
          <v-card-title>Authentification</v-card-title>
          <v-card-text>
            <v-row v-if="errorMessage">
              <v-col>
                <div class="errorMessage">{{ errorMessage }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="loginInfo.username" placeholder="Identifiant"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="loginInfo.password" placeholder="Mot de passe" type="password"
                              @keyup.enter="login"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="login">Connexion</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginView",
  data() {
    return {
      loginInfo: {
        username: null,
        password: null,
      },
      errorMessage: null

    }
  },
  methods: {
    login() {
      let that = this
      axios.post(process.env.VUE_APP_BACK + '/login', this.loginInfo).then(function (res) {
        //console.log(that)
        that.errorMessage = null
        that.$store.commit('initJwt', res.data)
        window.localStorage.setItem('jwt', res.data)
        that.$router.push('/panelAdmin')
      }).catch(function (err) {
        if(err.response)
        if (err.response.status === 401 || err.response.status === 403) {
          that.errorMessage = 'Identifiant ou mot de passe incorrect, veuillez réessayer.'
        } else {
          that.errorMessage = 'Une erreur est survenue'
        }
      })

    }
  }
}
</script>

<style scoped>

</style>