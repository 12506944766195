import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from '@/store/index.store'

import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://a634cbb58494c2e4614784373cd92410@o4506109886332928.ingest.sentry.io/4506109959733249",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,


  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
