import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProspectView from "@/views/ProspectView";
import SalonView from "@/views/SalonView";
import ProgrammeView from "@/views/ProgrammeView";
import LoginView from "@/views/LoginView";
import api from "@/plugins/api";
import store from '@/store/index.store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/panelAdmin',
        name: 'home',
        component: HomeView
    },
    {
        path: '/prospectsAdmin',
        name: "prospects",
        component: ProspectView
    },
    {
        path: '/salonsAdmin',
        name: "salons",
        component: SalonView
    },
    {
        path: '/programmesAdmin',
        name: "programmes",
        component: ProgrammeView
    }
    ,
    {
        path: '/loginAdmin',
        name: "Login",
        component: LoginView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/loginAdmin'];
    const authRequired = !publicPages.includes(to.path);
    let token = store.getters.jwt
    if (token === null) {
        token = window.localStorage.getItem('jwt')
    }

    if (authRequired) {
        const config = {
            headers: {Authorization: token},
            method: 'post',
            url: process.env.VUE_APP_BACK + '/verify'
        };
        api.get(config.url, config).then(function (res) {
            store.commit('initUser', res.data)
            next()
        }).catch(function () {
            return next('/loginAdmin');
        })
    } else {
        next();
    }

})

export default router
