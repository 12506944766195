<template>
  <div class="Home">
    <v-snackbar v-model="snackbar" :color="snackbarType" :timeout="3000" top>
      <v-icon>mdi-check</v-icon>
      {{ snackbarMsg }}
    </v-snackbar>
    <v-row class="ma-4">
      <v-col cols="12">
        <v-card>
          <v-card-title>Salons à synchroniser</v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Nom</th>
                  <th class="text-left">
                    Nombre de prospects à synchroniser
                  </th>

                  <th class="text-left">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in salonsProvisoires" :key="item.nom">
                  <td>{{ item.nom }}</td>
                  <td>{{ item.nombrePro }}</td>
                  <td>
                    <v-btn dark color="#004f91" @click="openLinkDialog(item)"
                    >Synchroniser
                    </v-btn
                    >
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto" rounded>
          <v-card-title>Historique de synchronisation</v-card-title>
          <v-data-table
              :headers="headers"
              :items="historique.slice().reverse()"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ format(new Date(item.createdAt), "DD/MM/YYYY - HH:mm") }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Selectionner le salon à associer
        </v-card-title>

        <v-card-text>
          <v-select
              v-model="selectedSalon"
              :items="salons"
              labels="Salon"
              item-text="valueName"
              item-value="valueName"
              placeholder="Salon"
          >
          </v-select>

          <v-menu
              ref="menu"
              v-model="dateSalonMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateSalon"
                  label="Date du salon"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateSalon"
                no-title
                scrollable
                @input="dateSalonMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="linkSalonProv()"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading-dialog
        class="loadingDialog"
        :opened="loadingOpened"
    ></loading-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import api from "@/plugins/api";
import LoadingDialog from "@/components/Dialogs/LoadingDialog";
import {format} from "fecha";
import * as Sentry from "@sentry/vue";

export default {
  name: "HomeView",
  components: {
    LoadingDialog,
  },
  async mounted() {
    await this.$store.dispatch('fetchSalonsSf')
    await this.$store.dispatch('fetchSalonsProvisoires')
    await this.$store.dispatch('fetchSalons')
    await this.$store.dispatch('fetchProgrammes')
    await this.$store.dispatch('fetchProspects')
    await this.$store.dispatch('fetchSfConfs')
    await this.$store.dispatch('fetchHistorique')
    this.specSF = this.$store.getters.sfConfs;
  },
  computed: {
    salonsProvisoires: function () {
      let output = [];
      let sp = this.$store.getters.salonsProvisoires;
      let p = this.$store.getters.prospects;

      for (let i = 0; i < sp.length; i++) {
        sp[i].nombrePro = p.filter(
            (x) => x.salon_nom === sp[i].nom && x.statut_SF === false
        ).length;
        if (sp[i].nombrePro > 0) output.push(sp[i]);
      }
      return output;
    },
    historique: function () {
      return this.$store.getters.historique;
    },
    salons: function () {
      return this.$store.getters.salonsSf;
    },
  },
  data() {
    return {
      dialog: false,
      salonProvisoireTable: {
        headers: [
          {
            text: "Nom",
            align: "start",
            sortable: false,
            value: "nom",
          },
          {
            text: "Nombre de prospects à synchroniser",
            align: "start",
            sortable: false,
            value: "nombrePro",
          },
          {
            text: "Actions",
            align: "end",
            sortable: false,
            value: "name",
          },
        ],
      },
      selectedProv: null,
      selectedSalon: null,
      dateSalon: null,
      dateSalonMenu: false,
      loadingOpened: false,
      snackbar: false,
      snackbarMsg: "",
      snackbarType: "",
      specSF: [],
      headers: [
        {
          text: "Salon Provisoire",
          align: "start",
          sortable: true,
          value: "salon_provisoire",
        },
        {
          text: "Salon SalesForce",
          align: "start",
          sortable: true,
          value: "salon_sf",
        },
        {
          text: "Date de synchronisation",
          align: "start",
          sortable: true,
          value: "createdAt",
        },
      ],
    };
  },
  methods: {
    async postLinkSalons() {
      return api.post(`${process.env.VUE_APP_BACK}/linkSalons`, {
        nom: this.selectedProv.nom,
        date_contact: this.dateSalon,
        salon_nom: this.selectedSalon,
      })
    },

    async postCheckProspect(prospect) {
      let checkData = {
        email: prospect.personEmail,
        recordType: process.env.VUE_APP_RECORD_TYPE_ID,
      };
      return api.post(`${process.env.VUE_APP_BACK}/sfCheckProspect`, checkData);
    },

    async postUpdateProspect(prospect, prospectSFId, prospectId) {
      return api.post(`${process.env.VUE_APP_BACK}/sfUpdateProspect`, {
        prospect: prospect,
        prospectSFId: prospectSFId,
        prospectId: prospectId,
      })
    },

    async postCreateProspect(prospect, prospectId) {
      return api.post(`${process.env.VUE_APP_BACK}/sfCreateProspect`, {
        prospect: prospect,
        prospectId: prospectId,
      })
    },

    async getProspects() {
      return api.get(`${process.env.VUE_APP_BACK}/prospects`);
    },

    async getHistorique() {
      return api.get(`${process.env.VUE_APP_BACK}/historique`, {});
    },

    async linkSalonProv() {
      let nbErreurs = 0;
      this.loadingOpened = true;

      try {
        const res = await this.postLinkSalons.call(this);
        this.$store.commit("setSalonsProvisoires", res.data);

        let p = res.data.prospects;
        for (let i = 0; i < p.length; i++) {
          const prospect = this.formatProspectForSF(p[i]);
          try {
            const resCheck = await this.postCheckProspect.call(this, prospect);

            if (resCheck.data.exist) {
              try {
                const resUpdate = await this.postUpdateProspect.call(this, prospect, resCheck.data.id, p[i].prospect_id);
                if (resUpdate.data.status === 400) {
                  nbErreurs++;
                }
                this.$store.commit("initProspect", (await this.getProspects.call(this)).data);
              } catch (error) {
                nbErreurs++;
              }
            } else {
              try {
                const resCreate = await this.postCreateProspect.call(this, prospect, p[i].prospect_id);
                if (resCreate.data.status === 400) {
                  nbErreurs++;
                }
                this.$store.commit("initProspect", (await this.getProspects.call(this)).data);
              } catch (error) {
                nbErreurs++;
              }
            }
          } catch (error) {
            Sentry.captureException(error);
            nbErreurs++;
          }
        }

        this.$store.commit("setHistorique", (await this.getHistorique.call(this)).data);

      } catch (error) {
        //console.error("Error on postLinkSalons");
        //console.log(error.message);
        Sentry.captureException(error);

      } finally {
        this.snackbarType = "success";
        this.snackbarMsg = `Synchronisation terminée, ${nbErreurs} erreurs`;
        this.snackbar = true;
        this.dialog = false;
        this.loadingOpened = false;
      }
    },
    setSpec(prospectToSync, specSF) {
      if (prospectToSync.spec_id.startsWith("[")) {
        let specIDs = JSON.parse(prospectToSync.spec_id)
        prospectToSync.specSf = specSF.filter((item) => specIDs.includes(item.id));
      } else {
        prospectToSync.specSf = [specSF.find((x) => x.id === prospectToSync.spec_id)];
      }
    },
    setOwnerAttributes(prospectToSync) {
      let firstSpec
      if (prospectToSync.spec_id.startsWith("[")) {
        let specIDs = JSON.parse(prospectToSync.spec_id)
        firstSpec = this.$store.getters.sfConfs.find((item) => specIDs[0] === item.id);
      } else {
        firstSpec = prospectToSync.specSf[0];
      }
      return {
        ownerId: firstSpec.owner_id,
        recordType: firstSpec.account_record_type,
        accountType: firstSpec.account_type,
      }
    },
    createProspectData(prospectToSync, ownerAttributes) {
      return {
        salutation: prospectToSync.civilite,
        lastName: prospectToSync.nom,
        firstName: prospectToSync.prenom,
        personEmail: prospectToSync.email,
        personMailingPostalCode: prospectToSync.code_postal,
        personMailingCity: prospectToSync.ville,
        Phone: prospectToSync.tel,
        recordTypeId: ownerAttributes.recordType,
        ownerId: ownerAttributes.ownerId,
        Type_compte__pc: ownerAttributes.accountType,
        Date_de_contact__pc: this.dateSalon,
        Statut__pc: "Prospect",
        origine_du_contact__pc: "Salon",
        Detail_de_l_origine__pc: prospectToSync.salon_nom,
        Niveau_de_formation__pc: prospectToSync.niveau.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, ""),
      };
    },
    formatPrograms(x, prospectToSync) {
      x.Programmes_choisis__c = prospectToSync.specSf.map(spec => spec.akeneo_id.toString()).join(";");
      x.Programmes_choisis__c = x.Programmes_choisis__c.toString();
    },
    formatProspectForSF(prospectToSync) {
      let specSF = this.$store.getters.sfConfs;
      this.setSpec(prospectToSync, specSF);
      let ownerAttributes = this.setOwnerAttributes(prospectToSync);
      let x = this.createProspectData(prospectToSync, ownerAttributes);
      this.formatPrograms(x, prospectToSync);

      if (prospectToSync.etablissement === null) {
        prospectToSync.etablissement = "";
      } else {
        if (prospectToSync.etablissement.startsWith("Saisie Libre :") || prospectToSync.etablissement.startsWith("Pas dans salesforce :")) {
          x.Description = prospectToSync.etablissement;
        } else {
          x.Etablissement_du_prospect__pc = prospectToSync.etablissement;
        }
      }
      Object.keys(x).forEach((key) => {
        if (x[key] === null || x[key] === "null") {
          delete x[key];
        }
      });
      return x;
    },

    openLinkDialog(prov) {
      this.selectedProv = prov;
      this.dialog = true;
    },
    format,
  },
};
</script>
